



























































import {
	Component,
	Vue,
	Prop,
	Watch,
	Emit,
} from 'vue-property-decorator';
import ChartJS, {
	ActiveElement,
	Chart,
	ChartData,
	ChartEvent,
	ChartType,
	InteractionItem,
	InteractionOptions,
	registerables,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getRelativePosition } from 'chart.js/helpers';
import { BarChart as Text } from '@/config/Text.json';
@Component({
	name: 'BarChart',
})
export default class BarChart extends Vue {
	@Prop({
		type: Boolean,
		default: true,
	})
	readonly isLoading!: boolean;
	@Prop({
		type: String,
		required: true,
	})
	readonly title!: string;
	@Prop({
		type: String,
		required: false,
	})
	readonly subtitle!: string;
	@Prop({
		type: String,
		required: true,
	})
	readonly chartType!: string;
	@Prop({
		type: String,
		required: false,
		default: 'x',
	})
	readonly axis!: string;
	@Prop({
		type: Object as () => ChartData,
		required: true,
	})
	readonly data!: ChartData;

	@Prop({
		type: Array as () => Array<number>,
		default: () => Array<number>(),
	})
	readonly extraData!: Array<number>;
	public chart!: Chart;
	public chart2!: Chart;
	
	@Prop({ type: String, default:'' }) readonly valores!:string;
	@Prop({ type: Array as () => Array<string>,	default: () => Array<string>(),	}) valoresItems!: Array<string>;
	
	@Prop({ type: Boolean, default:false })  showMenu!:boolean;
	@Prop({ type: Boolean, default:false })  dialog!:boolean;

	mounted() {
		this.cargarClasificacion();
		this.generateChart();
	}

	cargarClasificacion(){
		if (localStorage.getItem("clasificacion") != null) {
			let clasificacion = localStorage.getItem('clasificacion')
			this.valoresItems=[]
			console.log("Cargar CLASIFICACION",clasificacion)
			localStorage.getItem('clasificacion')?.split(",").forEach(element => {
				console.log("Elemento:",element)
				this.valoresItems.push(element)
			});

		}
		
	}
	dialogVisibilityChanged(value: boolean) {
		if (value) {
			console.log('El diálogo se ha mostrado');
			// Realizar acciones adicionales cuando el diálogo se muestra				
			setTimeout(()=>{
				this.generateChart2();
				console.log("activo el setTimeOut")
			},7000);
		} else {
			console.log('El diálogo se ha ocultado');
			// Realizar acciones adicionales cuando el diálogo se oculta
		}
	}

	abrir(){
		this.showMenu=true;
		console.log("Entro al metodo Abrir");
			// Realizar acciones adicionales cuando el diálogo se muestra				
			setTimeout(()=>{
				this.generateChart2();
				console.log("activo el setTimeOut")
			},200);
	}
	private generateChart() {
		console.log("Genero Chart")
		const chart: HTMLCanvasElement = this.$refs[
			'chart'
		] as HTMLCanvasElement;
		const chart2: HTMLCanvasElement = this.$refs[
			'chart2'
		] as HTMLCanvasElement;


		Chart.register(...registerables);
		Chart.register(ChartDataLabels);

		const chartInstance: Chart = new Chart(chart, {
			plugins: [
				{
					id: 'options.plugins.legend',
				},
			],
			type: this.chartType as ChartType,
			data: {
				datasets: [
			
				],
				labels: [],
			},
			options: {
				events: ['click', 'mousemove', 'touchstart'],
				indexAxis:
					this.axis === 'x' || this.axis === 'y'
						? this.axis
						: 'x',
				plugins: {
					legend: {
						display: false,
					},
					datalabels: {
						formatter: (value) =>
							'			' + Number(value).toFixed(1) +
							'%',
					},
					tooltip: {
						enabled: true,
						intersect: true,
						mode: 'point',
						callbacks: {
							title: (items) =>
								items[0].label,
							label: (items) =>
								`${this.$t("BarChart.toolTips.label") as string}${
									this.extraData[
										items.dataIndex
									]
								}`,
						},
					},
				},
				scales: {
					y: {
						display: true,
					},
					x: {
						display: true,
					},
				},
				onClick: this.labelClick,
			},
		});
		this.chart = chartInstance;
			
		const chartInstance2: Chart = new Chart(chart2, {
			plugins: [
				{
					id: 'options.plugins.legend',
				},
			],
			type: this.chartType as ChartType,
			data: {
				datasets: [
			
				],
				labels: [],
			},
			options: {
				events: ['click', 'mousemove', 'touchstart'],
				indexAxis:
					this.axis === 'x' || this.axis === 'y'
						? this.axis
						: 'x',
				plugins: {
					legend: {
						display: false,
					},
					datalabels: {
						formatter: (value) =>
							'			' + Number(value).toFixed(1) +
							'%',
					},
				},
				scales: {
					y: {
						display: true,
					},
					x: {
						display: true,
					},
				},
				onClick: this.labelClick2,
			},
		});

		this.chart2 = chartInstance2;
		console.log("Cargado Graficos 2....")
	}

	private generateChart2() {

		console.log("Genero Chart")
		const chart2: HTMLCanvasElement = this.$refs[
			'chart2'
		] as HTMLCanvasElement;


		Chart.register(...registerables);
		Chart.register(ChartDataLabels);

		const chartInstance2: Chart = new Chart(chart2, {
			plugins: [
				{
					id: 'options.plugins.legend',
				},
			],
			type: this.chartType as ChartType,
			data: {
				datasets: [
			
				],
				labels: [],
			},
			options: {
				events: ['click', 'mousemove', 'touchstart'],
				indexAxis:
					this.axis === 'x' || this.axis === 'y'
						? this.axis
						: 'x',
				plugins: {
					legend: {
						display: false,
					},
					datalabels: {
						formatter: (value) =>
							'			' + Number(value).toFixed(1) +
							'%',
					},
				},
				scales: {
					y: {
						display: true,
					},
					x: {
						display: true,
					},
				},
				onClick: this.labelClick2,
			},
		});
		this.chart2.destroy();
		this.chart2 = chartInstance2;
		console.log("Cargado Graficos 2....")
		this.chart2.data=this.chart.data;
		this.chart2.update();
	}

	@Watch('data')
	changedData(newVal: ChartData) {
		console.log("Datos changedData en el Grafico BarChart")
		console.log(newVal);
		this.chart.data = newVal;
		this.chart.update();
		this.chart2.data=newVal;
		this.chart2.update();
	}

	@Emit()
	public onClickBar(payload: string) {
		console.log("Emite el PayLoad:",payload)
		return payload;
	}

	labelClick(
		event: ChartEvent | MouseEvent,
		activeElement: ActiveElement[],
		chart: Chart
	) {
		const posClicked = getRelativePosition(
			event as MouseEvent,
			this.chart
		);

		console.log(event);
		console.log(posClicked);

		const activeClick = this.chart.getElementsAtEventForMode(
			event as Event,
			'point',
			this.chart.options as InteractionOptions,
			false
		);
		console.log(activeClick);
		if (activeClick.length > 0) {
			const elementClicked: InteractionItem =
				activeClick[0];
			const label: string = this.chart.data.labels![
				elementClicked.index
			] as string;
			const value = this.chart.data.datasets[
				elementClicked.index
			];
			console.log("Click en la etiqueta LABEL",label)
			console.log("Click en la etiqueta VALUE",value)
			console.log(label, value);
			this.onClickBar(label);
		}
	}

	labelClick2(
		event: ChartEvent | MouseEvent,
		activeElement: ActiveElement[],
		chart: Chart
	) {
		const posClicked = getRelativePosition(
			event as MouseEvent,
			this.chart2
		);

		console.log(event);
		console.log(posClicked);

		const activeClick = this.chart.getElementsAtEventForMode(
			event as Event,
			'point',
			this.chart2.options as InteractionOptions,
			false
		);
		console.log(activeClick);
		if (activeClick.length > 0) {
			const elementClicked: InteractionItem =
				activeClick[0];
			const label: string = this.chart.data.labels![
				elementClicked.index
			] as string;
			const value = this.chart.data.datasets[
				elementClicked.index
			];
			console.log("Click en la etiqueta LABEL",label)
			console.log("Click en la etiqueta VALUE",value)
			console.log(label, value);
			this.onClickBar(label);
		}
	}

	public listenClickRow() {
		console.log("Seleciono un valor para realizar un Emit:",this.valores)
		console.log(this.valores)
		this.$emit('changeEvent', this.valores);
	}
}
